<template>
  <el-container>
    <el-aside ref="aside" :width="showAside ? '232px' : '16px'" style="margin-right: 16px;">
      <el-button :icon="showAside ? 'el-icon-arrow-right' : 'el-icon-arrow-left'" class="treeToggle"
        @click="handleCollapse"></el-button>
      <transition name="el-fade-in-linear">
        <vm-tree v-show="showAside" class="treeSide" type="useUnit/false" @node-click="onNodeClick"
          @area-click="onAreaClick" @node-clear="onNodeClear">
        </vm-tree>
      </transition>
    </el-aside>
    <el-main style="padding:0;width:100vh">
      <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" :multi-selection="whichPage === 0"
        url="elevators/page" @clear="clearDateRange" @row-click="handleRowClick"
        @selection-change="handleSelectionChange">
        <template v-if="whichPage === 0" slot="toolbar">
          <el-button v-if="editAuth && platformNoAuth" class="addBtn" @click="handleAdd">
            {{ $t("common.add") }}
          </el-button>
          <el-button v-if="$i18n.isCn" :disabled="multipleDisabled" @click="handleECOD">
            EOCD
          </el-button>
          <el-button v-if="$i18n.isCn && testAuth" :disabled="multipleDisabled" @click="handleTest">
            {{ $t("elevator.test") }}
          </el-button>
          <el-button v-if="$i18n.isCn && checkAuth" :disabled="multipleDisabled" @click="handleCheck">
            {{ $t("elevator.check") }}
          </el-button>
          <el-button v-if="$i18n.isCn" :disabled="reportDisabled || multipleDisabled" @click="handleReport">
            {{ $l("elevator.report", "统计") }}
          </el-button>
          <el-button class="greenBtn" :loading="exportLoading" @click="exportExcel">
            {{ $t("event.export") }}
          </el-button>
        </template>
        <template slot="adSearch">
          <vm-search :label="$t('common.filter')">
            <el-input v-model.trim="search.filter" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
          </vm-search>
          <vm-search :label="$t('elevator.elevatorType')">
            <el-select v-model="search.productTypeId" :placeholder="$t('elevator.elevatorType')" clearable>
              <el-option v-for="item in productTypeOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <vm-search :label="$t('elevator.onlineStatus')">
            <el-select v-model="search.onlineStatus" :placeholder="$t('elevator.onlineStatus')" clearable>
              <el-option v-for="item in onlineStatusOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <!-- <vm-search v-if="false" :label="$t('elevator.activatedPartsStatus')">
            <el-select v-model="search.hasActivatedParts" :placeholder="$t('elevator.activatedPartsStatus')"
              :style="{ width: $l('200px', '100px') }" clearable>
              <el-option v-for="item in activatedPartsStatus" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search> -->
          <vm-search :label="$t('elevator.joinStatus')">
            <el-select v-model="search.inNetState" :placeholder="$t('elevator.joinStatus')" clearable>
              <el-option v-for="item in inNetStateOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <vm-search :label="$t('elevator.gateWayType')">
            <el-select v-model="search.gateWayType" :placeholder="$t('elevator.gateWayType')" clearable>
              <el-option v-for="item in gateWayTypeOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <vm-search v-if="$i18n.isCn" :label="$t('elevator.accessPlatform')">
            <el-select v-model="search.integrationKey" :placeholder="$t('elevator.accessPlatform')" clearable>
              <el-option v-for="item in integrationList" :key="item.integrationKey" :label="item.name"
                :value="item.integrationKey"></el-option>
            </el-select>
          </vm-search>
          <vm-search :label="$t('elevator.joinDate')">
            <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd" type="daterange"
              :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
              :end-placeholder="$t('common.endDate')" @change="handleDateChange">
            </el-date-picker>
          </vm-search>
          <vm-search :label="$t('elevator.exFactoryDate')">
            <el-date-picker v-model="exFactoryDateRange" value-format="yyyy-MM-dd" type="daterange"
                            :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
                            :end-placeholder="$t('common.endDate')" @change="handleExFactoryDateChange">
            </el-date-picker>
          </vm-search>
          <vm-search :label="$t('elevator.installationDate')">
            <el-date-picker v-model="installationDateRange" value-format="yyyy-MM-dd" type="daterange"
                            :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
                            :end-placeholder="$t('common.endDate')" @change="handleInstallationDateChange">
            </el-date-picker>
          </vm-search>
          <vm-search :label="$t('elevator.serviceDate')">
            <el-date-picker v-model="serviceDateRange" value-format="yyyy-MM-dd" type="daterange"
                            :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
                            :end-placeholder="$t('common.endDate')" @change="handleServiceDateChange">
            </el-date-picker>
          </vm-search>
          <vm-search :label="$t('elevator.follow')">
            <el-select v-model="search.follow" clearable>
              <el-option v-for="item in followStatusOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>

        </template>
        <el-table-column prop="no" :label="$t('elevator.no')" width="110" align="center">
          <template v-slot="scope">
            <div v-if="$i18n.isCn">
              <router-link v-if="whichPage === 1" :to="'/singleMonitor/' + scope.row.id" target="_blank">
                {{ scope.row.no }}
              </router-link>
              <router-link v-else :to="'/elevator/' + scope.row.id" target="_blank">{{ scope.row.no }}</router-link>
            </div>
            <div v-else>
              <router-link v-if="whichPage === 1" :to="'/singleMonitorOld/' + scope.row.id" target="_blank">
                {{ scope.row.no }}
              </router-link>
              <router-link v-else :to="'/elevator/' + scope.row.id" target="_blank">{{ scope.row.no }}</router-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="regNo" show-overflow-tooltip :label="$t('elevator.registerCode')"
          width="160"></el-table-column>
        <el-table-column prop="factoryNo" show-overflow-tooltip :label="$t('elevator.exFactoryNo')"
          width="130"></el-table-column>
        <el-table-column prop="name" width="160" show-overflow-tooltip
          :label="$t('dayWeekMonthManger.elevatorName')"></el-table-column>
        <el-table-column prop="dtuCode" :label="$t('elevator.dtuCode')" width="120" align="center"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="nodeCode" :label="$t('elevator.nodeCode')" width="60" align="center"></el-table-column>
        <el-table-column prop="videoUrl" :label="$t('elevator.video')" width="70" align="center">
          <template v-slot="scope">
            <!--电梯物联网教学 终端号 ：10000222836 轿厢视频FLV字段特殊处理-->
            <template v-if="scope.row.dtuCode === '10000222836' || scope.row.dtuCode === '10000229997'">
              <el-link
                v-if="scope.row.videoType === 1 && scope.row.videoState === 20 && scope.row.videoUrl !== '' && scope.row.videoUrl !== null"
                :underline="false" type="primary" @click="link(scope.row.videoUrl)">
                {{ $t('maintenanceArea.bridge') }}
              </el-link>
              <el-link
                v-if="scope.row.videoType === 1 && scope.row.videoState === 20 && scope.row.motorRoomVideoUrl !== '' && scope.row.motorRoomVideoUrl !== null"
                :underline="false" type="primary" @click="link(scope.row.motorRoomVideoUrl)">
                {{ $t('maintenanceArea.machineRoom') }}
              </el-link>
            </template>
            <template v-else>
              <el-link v-if="scope.row.gateWayType === 3
                && scope.row.deviceGatewayVo
                && scope.row.deviceGatewayVo.deviceGatewayInshVo.videoUrl" type="primary"
                @click="handleVideo(scope.row)">
                {{ $t('elevator.video') }}
              </el-link>
              <el-link v-if="scope.row.videoType === 2 && scope.row.tpVideoId" type="primary"
                @click="preview(scope.row.tpVideoId)">
                {{ $t('elevator.video') }}
              </el-link>
              <el-link v-if="scope.row.videoType === 2 && scope.row.tpVideoId" type="primary"
                @click="playBack(scope.row.tpVideoId)">
                {{ $t('tpLink.playBack') }}
              </el-link>
              <el-link
                v-if="scope.row.videoType === 1 && scope.row.videoState === 20 && scope.row.videoUrl !== '' && scope.row.videoUrl !== null"
                :underline="false" type="primary" @click="link(scope.row.videoUrl)">
                {{ $t('elevator.video') }}
              </el-link>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')" width="150"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="maintComName" :label="$t('elevator.maintenanced')" width="150"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="maintEmpName" :label="$t('elevator.maintWorker')" width="70"
          align="center"></el-table-column>
        <el-table-column prop="installComName" :label="$t('company.installationCompany')" width="150"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="installComTel" :label="$t('company.phone')" width="80"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="salesman" :label="$t('elevator.salesman')" width="70" align="center"></el-table-column>
        <el-table-column v-if="$i18n.isCn" :label="$t('elevator.accessPlatform')" width="100" align="center">
          <template v-slot="scope">
            <span v-for="(item, index) in scope.row.elevatorIntegrationVos" :key="index">
              <span>{{
                item.integrationKeyName + (index === scope.row.elevatorIntegrationVos.length - 1 ? "" :
                  ",")
              }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column v-if="whichPage === 0" prop="inNetState" :label="$t('elevator.joinStatus')" width="80"
          align="center">
          <template v-slot="scope">
            <el-tag v-if="scope.row.inNetState" type="success">{{ $t("elevator.joined") }}</el-tag>
            <el-tag v-else type="info">{{ $t("elevator.notJoined") }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column v-else prop="onlineStatus" :label="$t('monitor.onlineStatus')" width="80" align="center">
          <template v-slot="scope">
            <el-tag v-if="scope.row.onlineStatus === 1" type="success">{{ $t("monitor.online") }}</el-tag>
            <el-tag v-else type="info">{{ $t("monitor.offline") }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="$i18n.isCn" prop="hasActivatedParts" :label="$t('elevator.activatedPartsStatus')"
          width="80" align="center">
          <template v-slot="scope">
            <el-tag v-if="scope.row.hasActivatedParts" type="success">{{ $t("elevator.activated") }}</el-tag>
            <!-- <el-tag type="info" v-else>{{ $t("elevator.notActivated") }}</el-tag> -->
          </template>
        </el-table-column>
        <el-table-column prop="factoryDate" :label="$t('elevator.exFactoryDate')" width="100px"
                         align="center">
        </el-table-column>
        <el-table-column prop="installDate" :label="$t('elevator.installationDate')" width="100px"
                         align="center">
        </el-table-column>
        <el-table-column prop="useDate" :label="$t('elevator.serviceDate')" width="100px"
                         align="center">
        </el-table-column>
        <el-table-column v-if="editAuth && whichPage === 0" fixed="right" align="center"
          :label="$t('common.operate')" :width="$l('110px', '90px')">
          <template v-slot="scope">
            <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
            <el-button class="operateDelBtn" type="text" @click="handleDelete(scope.row)">
              {{
                $t("common.delete")
              }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column v-if="whichPage === 1" :label="$t('dayWeekMonthManger.operate')" width="60" align="center">
          <template v-slot="scope">
            <!-- <el-button type="text" @click="handleMonitor(scope.row)">
              {{$t("monitor.elevatorMonitor")}}
            </el-button> -->
            <div v-if="$i18n.isCn">
              <router-link :to="'/singleMonitor/' + scope.row.id" target="_blank">{{ $t("monitor.elevatorMonitor")
                }}</router-link>
            </div>
            <div v-else>
              <router-link :to="'/singleMonitorOld/' + scope.row.id" target="_blank">{{ $t("monitor.elevatorMonitor")
                }}</router-link>
            </div>
          </template>
        </el-table-column>
      </vm-table>
      <edit-dialog ref="editPage" @save-success="getList($event ? 1 : -1)"></edit-dialog>
      <vibration-data-list ref="vibrationDataPage"></vibration-data-list>
    </el-main>

    <t-p-link-play-back ref="playBack"></t-p-link-play-back>
  </el-container>
</template>
<script>
import EditDialog from "./ElevatorEdit.vue";
import VmTree from "../../components/VmTree.vue";
import ElMain from "../../../node_modules/element-ui/packages/main/src/main.vue";
import VibrationDataList from "./VibrationDataList";
import TPLinkPlayBack from "@/views/tenant/tplink/TPLinkPlayBack";
import auth from "@/util/auth";
import { Base64 } from "js-base64";

const moduleName = "elevators";
const moduleName_productType = "elevatorProductTypes";

export default {
  components: {
    EditDialog,
    ElMain,
    VmTree,
    VibrationDataList,
    TPLinkPlayBack,
  },
  props: {
    whichPage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showAside: false,
      platformNoAuth: auth.getCurrentOrgId() != 0,
      showOrgId: auth.getUsername() === "superAdmin" || auth.getTenantAdmin() === "true",
      editAuth: this.$auth(109),
      testAuth: this.$auth(136),
      checkAuth: this.$auth(137),
      local: window.localStorage.getItem("local"),
      search: {
        filter: "",
        gateWayType: "",
        startTime: "",
        endTime: "",
        exFactoryStartTime: "",
        exFactoryEndTime: "",
        installationStartTime: "",
        installationEndTime: "",
        serviceStartTime: "",
        serviceEndTime: "",
        productTypeId: "",
        integrationKey: "",
        maintComId: "",
        quantityOrganId: "",
        createComId: "",
        installComId: "",
        remakeComId: "",
        useUnitId: "",
        onlineStatus: "",
        inNetState: "",
        hasActivatedParts: "",
        districtId: "",
        follow: "",
      },
      loading: false,
      exportLoading: false,
      multipleDisabled: false,
      reportDisabled: false,
      multiSelect: [],
      dateRange: [],
      exFactoryDateRange: [],
      installationDateRange: [],
      serviceDateRange: [],
      productTypeOptions: [],
      inNetStateOptions: [
        { value: "false", label: this.$t("elevator.notJoined") },
        { value: "true", label: this.$t("elevator.joined") },
      ],
      activatedPartsStatus: [
        { value: "false", label: this.$t("elevator.notActivated") },
        { value: "true", label: this.$t("elevator.activated") },
      ],
      onlineStatusOptions: [
        { value: 0, label: this.$t("device.offline") },
        { value: 1, label: this.$t("device.online") },
      ],
      followStatusOptions: [
        { value: 0, label: this.$t("common.no") },
        { value: 1, label: this.$t("common.yes") },
      ],
      gateWayTypeOptions: [
        { value: 1, label: this.$l("elevator.gateWayType1") },
        { value: 3, label: this.$l("elevator.gateWayType3") },
      ],
      integrationList: [],
    };
  },
  mounted() {
    // alert(auth.getUsername() === "superAdmin" || auth.getTenantAdmin());
    this.getList(1);
    this.getProductTypeOptions();
    this.getIntegrationList();
  },
  methods: {
    preview(devId) {
      this.$http.get("tpLink/getPreviewUrl").then(res => {
        window.open(res.data + "?devId=" + Base64.encode(devId.toString()));
      });
    },
    playBack(devId) {
      this.$refs.playBack.open(devId);
    },
    onNodeClick(data) {
      if (data.type === "useUnit") {
        console.log(2222222);
        this.search.useUnitId = data.id;
        this.search.districtId = "";
        console.log(this.search);
        this.getList(1);
      }
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    handleExFactoryDateChange(dateRange) {
      if (dateRange != null) {
        this.search.exFactoryStartTime = dateRange[0];
        this.search.exFactoryEndTime = dateRange[1];
      } else {
        this.search.exFactoryStartTime = "";
        this.search.exFactoryEndTime = "";
      }
    },
    handleInstallationDateChange(dateRange) {
      if (dateRange != null) {
        this.search.installationStartTime = dateRange[0];
        this.search.installationEndTime = dateRange[1];
      } else {
        this.search.installationStartTime = "";
        this.search.installationEndTime = "";
      }
    },
    handleServiceDateChange(dateRange) {
      if (dateRange != null) {
        this.search.serviceStartTime = dateRange[0];
        this.search.serviceEndTime = dateRange[1];
      } else {
        this.search.serviceStartTime = "";
        this.search.serviceEndTime = "";
      }
    },
    onAreaClick(data) {
      console.log(11111111);
      this.search.districtId = data.id;
      this.search.useUnitId = "";
      console.log(this.search);
      this.getList(1);
    },
    onNodeClear() {
      this.search.districtId = "";
      this.search.useUnitId = "";
      this.getList(1);
    },
    getList(pageIndex) {
      this.loading = true;
      this.$refs.vmTable.getList(pageIndex);
      this.loading = false;
    },
    link(videoUrl) {
      window.open(videoUrl);
    },
    handleVideo(row) {
      this.loading = true;
      this.$http.get("deviceGatewayInsh/videoUrl/" + row.regNo).then(res => {
        this.loading = false;
        if (res.data.data) {
          window.open(res.data.data);
        }
      }).catch((error) => {
        this.loading = false;
      });
    },
    getProductTypeOptions() {
      this.$api.getList(moduleName_productType).then(response => {
        for (let productType of response.data) {
          let item = "";
          if (this.$i18n.isCn) {
            item = { value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeName };
          } else {
            item = { value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeNameEn };
          }
          this.productTypeOptions.push(item);
        }
      }).catch(() => {
        this.$message.error(this.$t("common.tip.getDataError"));
      });
    },
    handleSelectionChange(val) {
      this.multiSelect = val;
      this.reportDisabled = false;
      this.multipleDisabled = this.multiSelect.length > 1;
      this.multiSelect.forEach(elevator => {
        if (elevator.elevatorType === "escalator") {
          this.reportDisabled = true;
        }
      });
    },
    handleAdd() {
      this.$refs.editPage.open(0);
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id);
    },
    handleECOD() {
      if (this.multiSelect && this.multiSelect.length > 0) {
        if (this.multiSelect.length > 1) {
          this.$message.error("只能选择一条记录");
          return;
        }
        let row = this.multiSelect[0];
        this.$refs.vibrationDataPage.open(row.id, row.dtuCode, row.nodeCode);
      } else {
        this.$message.error("请先在列表选择需要操作的记录");
      }
    },
    handleTest() {
      if (this.multiSelect && this.multiSelect.length > 0) {
        if (this.multiSelect.length > 1) {
          this.$message.error("只能选择一条记录");
          return;
        }
        let row = this.multiSelect[0];
        window.open("#/elevatorTest/" + row.id);
      } else {
        this.$message.error("请先在列表选择需要操作的记录");
      }
    },
    handleCheck() {
      if (this.multiSelect && this.multiSelect.length > 0) {
        if (this.multiSelect.length > 1) {
          this.$message.error("只能选择一条记录");
          return;
        }
        let row = this.multiSelect[0];
        if (row.inNetState === true) {
          window.open("#/elevatorCheck/" + row.id);
        } else {
          this.$message.error("该电梯未入网");
        }
      } else {
        this.$message.error("请先在列表选择需要操作的记录");
      }
    },
    handleReport() {
      if (this.multiSelect && this.multiSelect.length > 0) {
        if (this.multiSelect.length > 1) {
          this.$message.error("只能选择一条记录");
          return;
        }
        let route = this.$router.resolve(
          {
            path: "/elevator/report/:elevatorId",
            name: "elevatorStatsReport",
            params: { elevatorId: this.multiSelect[0].id },
          });
        window.open(route.href, "_blank");
      } else {
        this.$message.error("请先在列表选择需要操作的记录");
      }
    },
    handleMonitor(row) {
      window.open("#/singleMonitor/" + row.id);
    },
    handleMonitorNew(row) {
      window.open("#/singleMonitorNew/" + row.id + "/" + row.registerNo);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("elevator.no") + " " + row.no + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById(moduleName, row.id).then(res => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.deleteError") + "," + error.response.data.message);
          }
        });
      });
    },
    clearDateRange() {
      this.dateRange = [];
      this.exFactoryDateRange = [];
      this.installationDateRange = [];
      this.serviceDateRange = [];
    },
    handleRowClick(row, column) {
      if (column && column.label === this.$t("common.operation")) {
        return;
      }
      this.$refs.vmTable.$refs.elTable.toggleRowSelection(row);
    },
    exportExcel() {
      this.exportLoading = true;
      let params = {
        filter: this.search.filter,
        startTime: this.dateRange != null ? this.dateRange[0] : "",
        endTime: this.dateRange != null ? this.dateRange[1] : "",
        exFactoryStartTime: this.exFactoryDateRange != null ? this.exFactoryDateRange[0] : "",
        exFactoryEndTime: this.exFactoryDateRange != null ? this.exFactoryDateRange[1] : "",
        installationStartTime: this.installationDateRange != null ? this.installationDateRange[0] : "",
        installationEndTime: this.installationDateRange != null ? this.installationDateRange[1] : "",
        serviceStartTime: this.serviceDateRange != null ? this.serviceDateRange[0] : "",
        serviceEndTime: this.serviceDateRange != null ? this.serviceDateRange[1] : "",
        districtId: this.search.districtId,
        useUnitId: this.search.useUnitId,
        productTypeId: this.search.productTypeId,
        integrationKey: this.search.integrationKey,
        onlineStatus: this.search.onlineStatus,
        inNetState: this.search.inNetState,
        hasActivatedParts: this.search.hasActivatedParts,
      };
      this.$api
        .exportFile("elevators/export", params, 60)
        .then(res => {
          this.exportLoading = false;
          let blob = new Blob([res.data]);
          let fileName = "";
          if (this.$i18n.isCn) {
            fileName = "电梯信息.xlsx";
          } else {
            fileName = "Elevator Info.xlsx";
          }
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    getIntegrationList() {
      this.$http.get("integrations/list").then(({ data }) => {
        this.integrationList = data;
      });
    },
    handleCollapse() {
      this.showAside = !this.showAside;
      if (this.showAside) {
        this.$refs.aside.$el.setAttribute("style", "margin-right: 20px");
      } else {
        this.$refs.aside.$el.setAttribute("style", "margin-right: 0px");
      }
    },
  },
};
</script>
